import { createContext } from "react";

/**
 * Predefined themes available in the application.
 * @type {{
 *   dark: string;
 *   light: string;
 * }}
 */
export const themes = {
  dark: "",
  light: "white-content",
};

/**
 * Context for managing the theme of the application.
 * @type {import("react").Context<{
 *   theme: string;
 *   changeTheme: () => void;
 * }>}
 */
export const ThemeContext = createContext({
  /**
   * The current theme of the application.
   * @type {string}
   */
  theme: themes.dark,

  /**
   * Function to change the theme of the application.
   * @type {() => void}
   */
  changeTheme: () => {},
});
