import React, { useRef, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardHeader, CardTitle, CardBody, Table, Button, Input } from 'reactstrap';
import NotificationAlert from 'react-notification-alert';
import PerfectScrollbar from 'perfect-scrollbar';
import Footer from 'components/Footer/Footer.js';
import FixedPlugin from 'components/FixedPlugin/FixedPlugin.js';
import { BackgroundColorContext } from 'contexts/BackgroundColorContext';
import io from 'socket.io-client';
import axios from 'axios';

const ENDPOINT = 'https://coral-app-88da2.ondigitalocean.app';

const replaceSubdomain = (url, newSubdomain) => {
  return url.replace(/^(https?:\/\/)(portal\.3deye\.me|admin\.3deye\.me)/, `$1${newSubdomain}`);
};

// TEMPORARY!
const cameraNameMapping = {
  '58201': 'Bogota #1 External 1',
  '58603': 'Bogota #1 External 2',
  '57829': 'Bossi Front  AUA Right Entrance',
  '57360': 'Bossi Front AUA Left Entrance '
};

const siteName = {
  '58201': 'Seinet',
  '58603': 'Seinet',
  '57829': 'Bossi',
  '57360': 'Bossi'
};

const noteMap = {
  '58201': `
    When suspicious activity (such as loitering) occurs, report it to the police.\n\n
    ⁠If a Premium Innovation employee is at the door, please call the supervisor on duty.  \n
    If anyone is in front of the door for more than 3 minutes, please report it to the supervisor on duty. \n
    Cars are not allowed to be parked in front of the gate at any time during the day (07:00-19:00). \n
    If a car is in front of the gate, please ask the driver to move it using the audio system or call the police immediately. \n
  `,
  '58603': `
    If you see a suspicious person between 6 AM and 8 PM and 9 PM and 11 PM, call the police.
    Report any black vehicle parked in front of the gate for more than 2 minutes to the supervisor on duty.
    When there is a delivery, report it to the supervisor on duty and inform the delivery driver via audio to please wait as someone is coming to the door.
  `,
  '57829': `
    Call police
  `,
  '57360': `
    If someone is loitering between 8 am and 9 pm, report the incident to Incident Management. <br>
    When the white Audi with license plate A-47631 arrives, report the arrival time to the client. <br>
    If someone is at the door and no one is in the office, please call +2976990050 to inform the client. <br>
    The SUV Tucson with license plate A-36642 cannot park in the first parking spot. If the car is parked in this spot, inform the driver via audio to move the vehicle immediately.
  `
};

const IncidentTable = ({ title, incidents, handleIncidentClick }) => (
  <Card className={`${title.toLowerCase().replace(' ', '-')}-table`}>
    <CardHeader>
      <CardTitle tag="h2">
        {title} ({incidents.length}) {/* Display number of incidents */}
      </CardTitle>
      <Table>
      <thead className="text-primary">
          <tr>
            <th className="large-text">Incident</th>
            <th className="large-text">Priority</th>
            <th className="large-text">Timestamp</th>
            <th className="large-text">Camera Name</th>
            <th className="large-text">Site Name</th>
          </tr>
        </thead>
      </Table>
    </CardHeader>
    <CardBody style={{ height: '300px', overflowY: 'auto' }}>     
      <Table className="tablesorter" responsive>
        <tbody>
          {incidents.map((incident, index) => (
            <tr key={index} onClick={() => handleIncidentClick(incident)}>
              <td className="large-text">{incident.ackType}</td>
              <td className="large-text">{incident.priority}</td>
              <td className="large-text">{incident.data ? incident.data.timestampUtc : ''}</td>
              <td className="large-text">{incident.data ? cameraNameMapping[incident.deviceId] || incident.deviceId : ''}</td>
              <td className="large-text">{incident.data ? siteName[incident.deviceId] || incident.deviceId : ''}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </CardBody>
  </Card>
);

const IncidentDetails = ({ incident, handleVerifyIncident, isVerified }) => (
  <Card className="incident-details">
    <CardBody>
      <h2>Incident Details</h2>
      {incident ? (
        <div>
          <p className="large-text">Type: {incident.ackType}</p>
          <p className="large-text">Device ID: {incident.deviceId}</p>
          {/* <p className="large-text">User: {incident.ackData.userId}</p> */}
          {/* <p className="large-text">Object Type: {incident.data.objectsFound[0].type}</p>
          <p className="large-text">Probability: {(incident.data.objectsFound[0].probability * 100).toFixed(2)}%</p> */}
          {!isVerified && (
            <Button color="primary" onClick={handleVerifyIncident}>Verify Incident</Button>
          )}
        </div>
      ) : (
        <p>Select an incident to see details</p>
      )}
    </CardBody>
  </Card>
);

const IncidentClip = ({ videoUrl }) => {
  const [brandedUrl, setBrandedUrl] = useState('');
  const brandedDomain = 'portalseinet.3deye.me';  // Replace with your branded domain

  useEffect(() => {
    if (videoUrl) {
      try {
        console.log('Original videoUrl:', videoUrl);

        const url = new URL(videoUrl);

        const cameraId = url.searchParams.get('cameraId');
        const startTime = url.searchParams.get('startTime');
        const accessToken = url.searchParams.get('access_token');

        console.log('Extracted Parameters:', {
          cameraId,
          startTime,
          accessToken,
        });

        if (cameraId && startTime && accessToken) {
          const updatedUrl = `https://${brandedDomain}/?cameraId=${cameraId}&startTime=${startTime}&access_token=${accessToken}`;

          console.log('Branded URL:', updatedUrl);

          setBrandedUrl(updatedUrl);
        } else {
          console.error('URL parameters missing or invalid:', {
            cameraId,
            startTime,
            accessToken,
          });
        }
      } catch (error) {
        console.error('Error processing URL:', error);
      }
    }
  }, [videoUrl]);

  const fallbackUrl = 'https://portalseinet.3deye.me/';

  return (
    <Card>
      <CardBody>
        <h2>Incident Clip</h2>
        <iframe
          src={brandedUrl || fallbackUrl}
          frameBorder="0"
          allowFullScreen
          style={{ width: '100%', height: '54vh' }}
          title="Incident Clip"
        />
      </CardBody>
    </Card>
  );
};


const IncidentMessage = ({ message }) => {
  const [editedMessage, setEditedMessage] = useState(message); // State to hold the edited message

  const handleChange = (e) => {
    setEditedMessage(e.target.value); // Update the edited message state
  };

  return (
    <Card className='incident-message-card'>
      <CardBody>
        <h2>Incident Message</h2>
        <Input
          type='textarea'
          value={editedMessage} 
          onChange={handleChange}
          className='message'
          rows={5} 
        /> 
        <p> Operator Message: {message}</p>
        {!editedMessage || !message && <p>No messages</p>}
      </CardBody>
    </Card>
  );
};

const ReportForm = ({ reportText, setReportText, handleSendReport, incidentSelected }) => (
  <Card className='report-card'>
    <CardBody>
      <h2>Send Report</h2>
      {incidentSelected ? (
        <div>
          <Input
            type="textarea"
            value={reportText}
            onChange={(e) => setReportText(e.target.value)}
            className="large-text-input"
          />
          <Button color="primary" onClick={handleSendReport} style={{ marginTop: '10px' }}>
            Send Report
          </Button>
        </div>
      ) : (
        <p>Select an incident to send report</p>
      )}
    </CardBody>
  </Card>
);

const ActionButtons = () => (
  <div className="button-group" style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
    <Button color="danger">Close Incident</Button>
    <Button color="warning">Audio</Button>
    <Button color="info">Guard</Button>
    <Button color="danger">Emergency</Button>
    <Button color="primary">Dispatch Drone</Button>
  </div>
);

const SiteNotes = ({ notes }) => (
  <Card className='site-notes-card'>
    <CardBody>
      <h2>Site Notes</h2>
      {notes ? (
        <p className='notes'>{notes}</p>
      ) : (
        <p>No site notes available</p>
      )}
    </CardBody>
  </Card>
);

const CameraNotes = ({ notes }) => (
  <Card className='camera-notes-card'>
    <CardBody>
      <h2>Camera Notes</h2>
      {notes ? (
        <div>
          <p className='additional-notes'>{notes}</p> 
        </div>
      ) : (
        <p>No camera notes available</p>
      )}
    </CardBody>
  </Card>
);

const ContactInformation = ({ contactInfo }) => (
  <Card className='contact-info-card'>
    <CardBody>
      <h2>Contact Information</h2>
      {contactInfo ? (
        <div>
          <p className='contact-info'>{contactInfo}</p>
        </div>
      ) : (
        <p>No contact information available</p>
      )}
    </CardBody>
  </Card>
);

const Incidents = () => {
  const mainPanelRef = useRef(null);
  const notificationAlertRef = useRef(null);
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const [unverifiedIncidents, setUnverifiedIncidents] = useState([]);
  const [verifiedIncidents, setVerifiedIncidents] = useState([]);
  const [selectedIncident, setSelectedIncident] = useState(null);
  const [videoUrl, setVideoUrl] = useState('');
  const [message, setMessage] = useState('');
  const [reportText, setReportText] = useState('');
  const [siteNotes, setSiteNotes] = useState('');
  const [cameraNotes, setCameraNotes] = useState('');
  const [contactInfo, setContactInfo] = useState('');
  const [missingIncidents, setMissingIncidents] = useState([]);

  useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      const ps = new PerfectScrollbar(mainPanelRef.current);
      document.body.classList.toggle('perfect-scrollbar-on');
      return () => {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      };
    }
  }, []);

  useEffect(() => {
    const socket = io(ENDPOINT, {
      transports: ['websocket', 'polling'],
    });
    socket.on('connect', () => {
      console.log('Connected to WebSocket server');
    });

    const incidentTracker = {};

    socket.on('webhookData', (data) => {
      console.log('Received webhook data:', data);
      data.forEach((incident) => {
        const { ackType, deviceId, data: incidentData } = incident;
        const incidentKey = `${ackType}-${deviceId}`;

        if (!incidentTracker[incidentKey]) {
          incidentTracker[incidentKey] = [];
        }

        const currentTime = new Date(incidentData.timestampUtc).getTime();
        incidentTracker[incidentKey] = incidentTracker[incidentKey].filter(
          (timestamp) => currentTime - timestamp <= 45000
        );
        incidentTracker[incidentKey].push(currentTime);

        if (incidentTracker[incidentKey].length >= 3) {
          const alreadyReported = missingIncidents.some(
            (inc) => inc.ackType === ackType && inc.deviceId === deviceId
          );
          if (!alreadyReported) {
            setMissingIncidents((prevIncidents) => [
              ...prevIncidents,
              { ...incident, priority: 'Missed' },
            ]);
          }
        }

        setUnverifiedIncidents((prevIncidents) => [
          ...prevIncidents,
          { ...incident, priority: 'Unverified' },
        ]);
      });

      console.log('Incident Tracker:', incidentTracker);
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from WebSocket server');
    });

    socket.on('connect_error', (error) => {
      console.error('WebSocket connection error:', error);
    });

    socket.on('error', (error) => {
      console.error('WebSocket error:', error);
    });

    return () => {
      socket.disconnect();
      console.log('Socket disconnected');
    };
  }, []);

  const handleIncidentClick = (incident) => {
    console.log('Selected incident:', incident);
    setSelectedIncident(incident);
    if (incident.data) {
      setMessage(incident.ackData.message);
      setVideoUrl(incident.data.sharedVideoUrl);
    } 
    if (incident.ackData == undefined) {
        setMessage('')   
    }

    else {
      setMessage('');
      setVideoUrl('');
    }

    // Placeholder for setting notes and contact info
    setSiteNotes('Example site notes for the selected incident.');
    setCameraNotes(noteMap[incident.deviceId] || 'No camera notes available');    
    setContactInfo('Ludwin Werleman - ludwin@bossisecurity.com - +297 586 3405');
  };

  const handleVerifyIncident = async () => {
    if (selectedIncident) {
      try {
        setUnverifiedIncidents((prevIncidents) =>
          prevIncidents.filter((inc) => inc !== selectedIncident)
        );
        setVerifiedIncidents((prevIncidents) => [...prevIncidents, selectedIncident]);
        setSelectedIncident(null);
      } catch (error) {
        console.error('Error logging incident:', error);
        alert('Failed to log incident. Retrying...');
      }
    }
  };

  const notify = (place) => {
    var options = {
      place: place,
      message: (
        <div>
          <div>Report sent!</div>
        </div>
      ),
      type: "success",
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const handleSendReport = async () => {
    if (reportText.trim() !== '') {
      console.log('Report:', reportText);

      try {
        await axios.post(`${ENDPOINT}/send-report`, {
          reportText,
          selectedIncident,
        });
        setReportText('');
        notify("bc");
      } catch (error) {
        console.error('Error sending report:', error);
        alert('Error sending report');
      }
    } else {
      alert("Please enter a report before sending.");
    }
  };

  useEffect(() => {
    console.log('Unverified Incidents:', unverifiedIncidents);
    console.log('Verified Incidents:', verifiedIncidents);
    console.log('Selected Incident:', selectedIncident);
    console.log('Missing Incidents:', missingIncidents);
  }, [unverifiedIncidents, verifiedIncidents, selectedIncident, missingIncidents]);

  return (
    <BackgroundColorContext.Consumer>
      {({ color, changeColor }) => (
        <div className="wrapper">
          <div className="main-panel" ref={mainPanelRef}>
            <NotificationAlert ref={notificationAlertRef} />
            <div className="content">
              <Container fluid>
                <Row>
                  <Col lg="4" md="10">
                    <IncidentTable
                      title="Verified Incidents"
                      incidents={[...unverifiedIncidents, ...missingIncidents]}
                      handleIncidentClick={handleIncidentClick}
                    />
                  </Col>
                  <Col md="2">
                    <IncidentDetails 
                      incident={selectedIncident} 
                      handleVerifyIncident={handleVerifyIncident}
                      isVerified={verifiedIncidents.includes(selectedIncident)}
                    />
                  </Col>
                  <Col lg="6" md="12">
                    <IncidentTable
                      title="Assigned Incidents"
                      incidents={verifiedIncidents}
                      handleIncidentClick={handleIncidentClick}
                    />
                    <ActionButtons />
                  </Col>
                  <Col lg="6" md="12">
                    <IncidentClip videoUrl={videoUrl} />
                  </Col>
                  <Col lg="2" md="8">
                    <IncidentMessage message={selectedIncident ? selectedIncident.ackData.message : ''} />
                  </Col>
                  <Col lg="4" md="10">
                    <ReportForm 
                      reportText={reportText}
                      setReportText={setReportText}
                      handleSendReport={handleSendReport}
                      incidentSelected={selectedIncident}
                    />
                  </Col>
                  <Col lg="4" md="6">
                    <SiteNotes notes={siteNotes} />
                  </Col>
                  <Col lg="4" md="6">
                    <CameraNotes notes={cameraNotes} />
                  </Col>
                  <Col lg="4" md="6">
                    <ContactInformation contactInfo={contactInfo} />
                  </Col>
                </Row>
              </Container>
            </div>
            <Footer fluid />
            <FixedPlugin bgColor={color} handleBgClick={changeColor} />
          </div>
        </div>
      )}
    </BackgroundColorContext.Consumer>
  );
};

export default Incidents;
