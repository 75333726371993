
import Dashboard from "views/Dashboard.js";
import Incidents from "views/Incidents.js";
import Triage from "views/Triage.js";


var routes = [
  
  {
    path: "/triage",
    name: "Triage",
    icon: "tim-icons icon-video-66",
    component: <Triage />,
    layout: "/admin",
  },
    
  {
    path: "/incidents",
    name: "Incidents",
    icon: "tim-icons icon-single-copy-04",
    component: <Incidents />,
    layout: "/admin",
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: <Dashboard />,
    layout: "/admin",
  },


   

];
export default routes;
