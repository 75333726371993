/**
 * Context for managing background colors in the application provided by CreativeTim's bootstrap library.
 * @type {import("react").Context<{
*   color: string;
*   changeColor: (color: string) => void;
* }>}
*/

import { createContext } from "react";

export const backgroundColors = {
  primary: "primary",
  blue: "blue",
  green: "green",
  brown: "brown",
 };
 
 

export const BackgroundColorContext = createContext({
 /**
  * The current background color.
  * @type {string}
  */

 /**
  * Function to change the background color.
  * @param {string} color The new color to set.
  * @returns {void}
  */
 changeColor: (color) => {},



color: backgroundColors.blue
});

/**
* Predefined background colors available in the application.
* @type {{
*   primary: string;
*   blue: string;
*   green: string;
*   brown: string;
* }}
*/
