import React, { useState } from "react";
import { Button, Form, FormGroup, Label, Input, Card, CardBody, CardHeader, Alert, Row, Col } from "reactstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logo from "assets/img/400PngdpiLogoCropped.png";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";
import FixedPlugin from "components/FixedPlugin/FixedPlugin";
/**
 * Login component for user authentication.
 * @component
 * @param {Object} props - Component props.
 * @param {Function} props.onLogin - Function to call upon successful login.
 * @returns {JSX.Element}
 */
const Login = ({ onLogin }) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // State for error message

  /**
   * Handles the form submission for user login.
   * @param {React.FormEvent<HTMLFormElement>} e - Form submit event.
   */
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://coral-app-88da2.ondigitalocean.app/api/login', { username, password });
      onLogin(response.data.accessToken, response.data.refreshToken);
      setErrorMessage(""); // Clear error message on successful login
      navigate("/admin/triage"); // Redirect to selection page after successful login
    } catch (error) {
      console.error('Login error:', error.response ? error.response.data : error.message);
      setErrorMessage(error.response ? error.response.data.errorDescription : error.message); // Set error message
    }
  };

  return (
    <BackgroundColorContext.Consumer>
    {({ color, changeColor }) => (
    <div className="content">
      <Row className="justify-content-center">
        <Col md="4">
          <Card>
          <img src={logo} alt="Logo" style={{marginLeft:'130px', position:'relative',marginTop:'10px', height:"100px", width: "250px"}} />

            <CardHeader>
              <h3 className="title" style={{textAlign:'center', visibility:'hidden'}}>Securus Login</h3>

            </CardHeader>
            <CardBody>
              
              {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
              <Form onSubmit={handleLogin}>
                <FormGroup>
                  <Label for="email">Username</Label>
                  <Input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </FormGroup>
                <Button type="submit" color="primary">
                  Login
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
       <FixedPlugin bgColor={color} handleBgClick={changeColor} />
       </div>

      ) }

    </BackgroundColorContext.Consumer>

  );
};

export default Login;
