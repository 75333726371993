/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Row, Col, Table } from "reactstrap";
import logo from "assets/img/400PngdpiLogoCropped.png";
import io from "socket.io-client";

const ENDPOINT = 'https://coral-app-88da2.ondigitalocean.app';

const cameraNameMapping = {
  '58201': 'Bogota #1 External 1',
  '58603': 'Bogota #1 External 2',
  '57829': 'Bossi Front  AUA Right Entrance',
  '57360' : 'Bossi Front AUA Left Entrance '
  // Add more deviceId and camera name mappings as needed
};

const siteName = {
  '58201': 'Seinet',
  '58603': 'Seinet',
  '57829': 'Bossi',
  '57360': 'Bossi'
};

const Triage = ({ onLogout }) => {
  const [embedUrl, setEmbedUrl] = useState("");
  const [unverifiedIncidents, setUnverifiedIncidents] = useState([]);
  const [missingIncidents, setMissingIncidents] = useState([]);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState("");

  useEffect(() => {
    const accessToken = sessionStorage.getItem("accessToken");
    const baseUrl = "https://alarmseinet.3deye.me/";
    const embedUrlWithToken = `${baseUrl}?access_token=${accessToken}`;
    setEmbedUrl(embedUrlWithToken);
  }, []);

  useEffect(() => {
    const socket = io(ENDPOINT , {
      transports: ['websocket', 'polling'],
    })  ;
    socket.on('connect', () => {
      console.log('Connected to WebSocket server');
    });

    const incidentTracker = {};

    socket.on('webhookData', (data) => {
      console.log('Received webhook data:', data);
      setUnverifiedIncidents((prevIncidents) => [...prevIncidents, ...data]);

      data.forEach(incident => {
        const { ackType, deviceId, data: incidentData } = incident;
        const incidentKey = `${ackType}-${deviceId}`;

        if (!incidentTracker[incidentKey]) {
          incidentTracker[incidentKey] = [];
        }

        const currentTime = new Date(incidentData.timestampUtc).getTime();
        incidentTracker[incidentKey] = incidentTracker[incidentKey].filter(timestamp => currentTime - timestamp <= 45000);
        incidentTracker[incidentKey].push(currentTime);

        if (incidentTracker[incidentKey].length >= 3) {
          setMissingIncidents((prevIncidents) => {
            const alreadyReported = prevIncidents.some((inc) => inc.ackType === ackType && inc.deviceId === deviceId);
            if (!alreadyReported) {
              return [...prevIncidents, incident];
            }
            return prevIncidents;
          });
        }
      });

      console.log('Incident Tracker:', incidentTracker);
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from WebSocket server');
    });

    socket.on('connect_error', (error) => {
      console.error('WebSocket connection error:', error);
    });

    socket.on('error', (error) => {
      console.error('WebSocket error:', error);
    });

    return () => {
      socket.disconnect();
      console.log('Socket disconnected');
    };
  }, []);

  useEffect(() => {
    console.log('Unverified Incidents:', unverifiedIncidents);
    console.log('Missing Incidents:', missingIncidents);
  }, [unverifiedIncidents, missingIncidents]);

  const handleIncidentClick = (incident) => {
    console.log('Selected incident:', incident);
    setSelectedVideoUrl(incident.ackData ? incident.data.sharedVideoUrl : '');
  };

  const filteredMissingIncidents = missingIncidents.filter(incident => incident.ackType === 'Normal');

  return (
    <div className="content">
      <Row>
        <Col md="8">
          <Card style={{ width: '100%', height: '90vh', marginTop: '2px', boxSizing: 'border-box' }}>
            <CardHeader style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
              <h1 className="title" style={{ flex: 1, textAlign: 'left', visibility: 'hidden' }}>Operator Portal</h1>
              <img src={logo} alt="Logo" style={{ position: 'absolute', marginBottom: '-28px', height: "120px", width: "250px" }} />
            </CardHeader>
            <CardBody>
              <iframe
                src={embedUrl}
                frameBorder="0"
                allowFullScreen
                style={{ width: '100%', height: '100%', marginTop: '-10px', border: '0' }}
              />
            </CardBody>
          </Card>
        </Col>
        <Col md="4">
          <Card style={{ height: '90vh', marginTop: '2px', boxSizing: 'border-box' }}>
            <CardHeader style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
              <h1 className="title">Missed Incidents</h1>
            </CardHeader>
            <CardBody style={{ height: '60vh', overflowY: 'auto' }}>
              <Table className="tablesorter" responsive>
                <thead className="text-primary">
                  <tr>
                    <th className="large-text">Incident</th>
                    <th className="large-text">Timestamp</th>
                    <th className="large-text">Camera Name</th>
                    <th className="large-text">Site Name</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredMissingIncidents.map((incident, index) => (
                    <tr key={index} onClick={() => handleIncidentClick(incident)}>
                      <td className="large-text">{incident.ackType}</td>
                      <td className="large-text">{incident.data ? incident.data.timestampUtc : ''}</td>
                      <td className="large-text">{incident.data ? cameraNameMapping[incident.deviceId] || incident.deviceId : ''}</td>
                      <td className="large-text">{incident.data ? siteName[incident.deviceId] || incident.deviceId : ''}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {filteredMissingIncidents.length === 0 && (
                <p>No missing incidents reported at the moment.</p>
              )}
            </CardBody>
            {selectedVideoUrl && (
              <CardBody style={{marginBottom:'300px'}}>
                <h4>Missed Incident Clip:</h4>
                <iframe
          src={selectedVideoUrl}
          frameBorder="0"
          allowFullScreen
          style={{ width: '100%', height: '47vh' }}
        />
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Triage;
