/**
 * 
 * NOT DONE! Currently storing sensitive data in session storage until database is made
 * for proper data storage!
 */
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import AdminLayout from "layouts/Admin/Admin.js";
import Triage from "./views/Triage.js";
import Login from "./components/Login/Login.js";
import Dashboard from "views/Dashboard.js";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper.js";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper.js";

const App = () => {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const accessToken = sessionStorage.getItem("accessToken");
    if (accessToken) {
      setAuthenticated(true);
    }
  }, []);

  const handleLogin = (accessToken, refreshToken) => {
    setAuthenticated(true);
    sessionStorage.setItem("accessToken", accessToken);
    sessionStorage.setItem("refreshToken", refreshToken);
  };

  const handleLogout = () => {
    setAuthenticated(false);
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("refreshToken");
    window.location.reload();
  };

  return (
    <ThemeContextWrapper>
      <BackgroundColorWrapper>
        <BrowserRouter>
            <Routes>
              <Route
                path="/login"
                element={
                  authenticated ? (
                    <Navigate to="/login" replace />
                  ) : (
                    <Login onLogin={handleLogin} />
                  )
                }
              />
              <Route path="/admin/*" element={<AdminLayout />} />
              <Route
                path="*"
                element={<Navigate to="/login" replace />}
              />

              <Route
                path="/dashboard"
                element={
                  authenticated ? (
                    <Dashboard/>
                    
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              {/* <Route path="/as" element={<IncidentsLayout />} /> */}
              <Route
                path="/triage"
                element={
                  authenticated ? (
                    <Triage onLogout={handleLogout} />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
            </Routes>
        </BrowserRouter>
      </BackgroundColorWrapper>
    </ThemeContextWrapper>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
